import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { urls } from './system.constants';

@Injectable({
  providedIn: 'root'
})
export class UserService {

	constructor(private http: HttpService) { 

  }

  // Get user profile
  getUserProfile(user_id: string) {
  	return new Promise((resolve, reject) => {
      this.http.get(urls.profiles + '/' + 'complete' + '/' + user_id, '1.0.0')
        .then((res: any) => {
        resolve(res);
        }, err => {
         reject(err);
      })
    })
  }

  // Update user profile
  updateUserProfile(user_id: string, postData) {
  	return new Promise((resolve, reject) => {
      this.http.put(urls.profiles + '/' + 'complete' + '/' + user_id, '1.0.0', postData)
        .then((res: any) => {
        resolve(res);
        }, err => {
         reject(err);
      })
    })
  }

  // Change User Password
  changePassword(user_id: string, postData) {
    return new Promise((resolve, reject) => {
    this.http.post(urls.change_password + "/" + user_id, '1.0.0', postData)
      .then((res: any) => {
      resolve(res);
      },err => {
      reject(err);
      })
    })
  }

  // Check if bvn exists
  checkIfBvnExists(bvn: string) {
    return new Promise((resolve, reject) => {
    this.http.get(urls.profiles + "/" + "bvn" + "/" + bvn, '1.0.0')
      .then((res: any) => {
      resolve(res);
      },err => {
      reject(err);
      })
    })
  }

}