import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { HttpService } from './http.service';
import { urls } from './system.constants';
import { AlertController } from '@ionic/angular';
import { NavController } from '@ionic/angular';
import { StorageService } from './storage.service';
import { PopupNotificationsService } from './popup-notifications.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private popupNotificationsService: PopupNotificationsService, private storageService: StorageService, private navController: NavController, public alertCtrl: AlertController, private router: Router, private toastCtrl: ToastController, public loadingCtrl: LoadingController, private http: HttpService) { 

  }

  // Register new users
  async register(postData) {
    const loading  = await this.loadingCtrl.create({ 
        cssClass: 'loader',
        spinner: null
    });
    await loading.present();
  	return new Promise((resolve, reject) => {
      this.http.post(urls.register, '1.0.0', postData)
        .then((res: any) => {
        resolve(res);
        loading.dismiss();
        }, err => {
        loading.dismiss();
         reject(err);
      })
    })
  }

  // Login users
  login(postData) {
    this.popupNotificationsService.startLoader();
  	return new Promise((resolve, reject) => {
      this.http.post(urls.login, '1.0.0', postData)
        .then((res: any) => {
          resolve(res);
          this.popupNotificationsService.stopLoader();
        }, err => {
          reject(err);
          this.popupNotificationsService.stopLoader();
      })
    })
  }

  // Logout from server
  logoutFromServer(user_id: string) {
    this.popupNotificationsService.startLoader();
    return new Promise((resolve, reject) => {
      this.http.get(urls.logout + '/' + user_id, '1.0.0')
        .then((res: any) => {
          resolve(res);
          this.popupNotificationsService.stopLoader();
        }, err => {
          if (err.status == 404) {
            return this.clearStorage()
          }
          reject(err);
          this.popupNotificationsService.stopLoader();
      })
    })
  }

  // Logout without modal
  logOut() {
    var user_id = localStorage.getItem('user_id');
    if (user_id == undefined) {
      // Clear all storages
      this.clearStorage();
      this.popupNotificationsService.stopLoader();
      return this.navController.navigateRoot('/intro');
    }
    this.logoutFromServer(user_id)
    .then((res: any) => {
      // Clear all storages
      this.clearStorage();
      this.navController.navigateRoot('/intro');
      this.popupNotificationsService.presentToast(res.message);
    })
    .catch(err => {
      this.popupNotificationsService.presentToast(err.error.message || 'Connection error!')
    })
  }

  // Logout with modal
  async logout() {
    const alert = await this.alertCtrl.create({
      header: 'Are you sure you want to logout?',
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Continue",
          handler: () => {
            this.logOut();
          }
        }
      ]
    });
    await alert.present();
  }

  // Clear all storage
  clearStorage() {
    localStorage.clear();
    this.storageService.clear();
  }

  // Get basic token
  getToken() {
    return !!localStorage.getItem('basic_token') && !!localStorage.getItem('user_id')
  }

}