import { Injectable } from '@angular/core';
import { PopoverController, LoadingController, ToastController } from '@ionic/angular';
import { PopoverComponent } from '../components/popover/popover.component';

import { NgxUiLoaderService } from "ngx-ui-loader";

@Injectable({
  providedIn: 'root'
})
export class PopupNotificationsService {

	constructor(private ngxService: NgxUiLoaderService, public loadingController: LoadingController, private toastCtrl: ToastController, private popoverController: PopoverController) { }

  // Start jobs
  run() {
    // Start finding pending messages
    var interval_id1 = setInterval(() => {
      
    }, 10000)
  }

  /* Notification popups starts */

  // Show pending messages in app popup notification
  async showPopupNotification(interval_id, title, description, avatar) {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      cssClass: 'popover-class',
      animated: true,
      componentProps: {
        'interval_id': interval_id,
        'avatar': avatar,
        'title': title,
        'description': description,
      },
      mode: 'ios',
    });
    await popover.present();
    const { data } = await popover.onDidDismiss();
    this.dismiss();
    if (data) {
      clearInterval(data.interval_id)
    }
  }

  // Dismiss popup
  dismiss() {
    this.popoverController.dismiss();
  }

  // Toast controller
  async presentToast(text) {
    const toast = await this.toastCtrl.create({
      message: text,
      duration: 5000,
      position: 'top'
    });
    toast.present();
  }

  // Start loading controller
  async startLoader() {
    this.ngxService.start();
  }

  // Stop loading controller
  async stopLoader() {
    this.ngxService.stop(); 
  }

  /* Notification popups ends */

  /* Notification job schedullers starts */

  // Show wallet notification
  showWalletNotification() {
    setTimeout(() => {
      // Dismiss any open popover
      this.dismiss();
      var title = 'MOBILE <br> <span class="flame-color"> WALLET </span>';
      var description = 'We recommed using the inbuit wallet for your daily transactions and purchases as a way to secure your money. Benefits includes refunds on cancelled orders, discounts, cashbacks and more. Top up your wallet today to enjoy!';
      var avatar = 'assets/wallet.svg';
      this.showPopupNotification(null, title, description, avatar)
    }, 10000)
  }

  /* Notification job schedullers ends */

}