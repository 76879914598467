import { Component } from '@angular/core';
import { AuthService } from '../providers/auth.service';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { UserService } from '../providers/user.service';
import { SystemService } from '../providers/system.service';
import { ModalController } from '@ionic/angular';
import { VerifyOtpPage } from './verify-otp/verify-otp.page';
import { PopupNotificationsService } from '../providers/popup-notifications.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private popupNotificationsService: PopupNotificationsService, private modalController: ModalController, private systemService: SystemService, private userService: UserService, private platform: Platform, public router: Router, private authService: AuthService) {
    this.platform.ready().then(() => {
      this.initializeApp();
    })
  }

  /* Routes and navigation starts */

  // Go to register
  goToRegister() {
    this.router.navigate(['/create-account'])
  }

  // Auto login
  autoLogin() {
    return this.router.navigate(['/tabs'], { replaceUrl: true })
  }

  // Go to BVN validation
  goToBvnValidation() {
    return this.router.navigate(['/bvn-validation'], { replaceUrl: true })
  }

  /* Routes and navigation ends */

  // Initialize app
  initializeApp() {
    if (this.authService.getToken()) {
        var user_id = localStorage.getItem('user_id');
        return this.validateProfile(user_id);
    }
    this.authService.logOut();
  }

  // Validate profile
  validateProfile(user_id) {
    this.userService.getUserProfile(user_id)
    .then((res: any) => {
      this.verifyProfile(res);
    })
    .catch(err => {
      console.log(err);
      this.authService.logOut();  
      this.popupNotificationsService.presentToast(err.error.message || 'Connection error!');    
    })
  }

  // Verify profile
  verifyProfile(data) {
    if (!data.user.first_name || !data.user.last_name || !data.user.email || !data.user.phone) {
      return this.goToBvnValidation();
    }
    if (data.user.phone && data.user.phone_verified != true) {
      return this.sendOTPVerification(data.user.user_id, data.user.country_code, data.user.phone);
    }
    return this.autoLogin();
  }

  // Send OTP verification
  sendOTPVerification(user_id, country_code, phone) {
    const postData = {
      CountryCode: country_code,
      From: 'upayd',
      To: phone,
    }
    this.systemService.requestVerificationCode(postData)
    .then((res: any) => {
      this.openVerifyOtp(user_id, country_code, phone, res.message)
    })
    .catch(err => {
      console.log(err);
      this.authService.logOut();  
      this.popupNotificationsService.presentToast(err.error.message || 'Connection error!');    
    })
  }

  // Open verify OTP modal
  async openVerifyOtp(user_id, country_code, phone, otp_code) {
    const modal = await this.modalController.create({
      component: VerifyOtpPage,
      componentProps: { 
        'country_code': country_code,
        'otp_code': otp_code,
        'phone': phone,
      },
      swipeToClose: true,
      mode: 'ios',
      backdropDismiss: true,
      animated: true,
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data !== undefined) {
      if (data.status === true) {
        this.popupNotificationsService.presentToast('Verification successful!');      
        return this.updateUserPhone(user_id, phone);
      }
    }
  }

  // Update user phone
  updateUserPhone(user_id, phone) {
    const postData = {
      phone: phone,
      phone_verified: true,
      last_verified_phone: phone,
    }
    this.popupNotificationsService.startLoader();
    this.userService.updateUserProfile(user_id, postData)
    .then((res: any) => {
      console.log(res)
      this.popupNotificationsService.stopLoader();
      this.autoLogin();
    })
    .catch(err => {
      console.log(err)
      this.authService.logOut(); 
      this.popupNotificationsService.stopLoader();  
      this.popupNotificationsService.presentToast(err.error.message || 'Connection error!');      
    })
  }


}
