import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { urls } from './system.constants';
import { PopupNotificationsService } from './popup-notifications.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private headers;
  constructor(
    private http: HttpClient, 
    private popupNotificationsService: PopupNotificationsService, 
    public alertCtrl: AlertController, 
    private router: Router, 
    private toastCtrl: ToastController, 
    public loadingController: LoadingController) {
      this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept-Version', '2.0.0')
      .set('Role', 'customer');
  }

  // API get method
  async get(url, version){
    return new Promise((resolve, reject) => {
      if(!url) reject("URL is missing");
      if(!version) reject("version is missing");
      
      this.headers.set('accept-version', version);
      var token = localStorage.getItem('basic_token');

      this.http.get(url, {'headers': this.headers.set('Authorization', `${token}`)})
        .subscribe((res: any) => {
          resolve(res);
        }, err => {
          if (err.status == 409) {
            return this.logOut()
          }
          reject(err)
        });
    });
  }

  // API post method
  async post(url, version, postdata){
    return new Promise((resolve, reject) => {
      if(!url) reject("URL is missing");
      if(!version) reject("version is missing");

      this.headers.set('accept-version', version);
      var token = localStorage.getItem('basic_token');

      this.http.post(url, postdata, {'headers': this.headers.set('Authorization', `${token}`)}) 
        .subscribe((res: any) => {
          resolve(res);
        }, err => {
          if (err.status == 409) {
            return this.logOut()
          }
          reject(err);
        })
    })
  }

  // API put method
  async put(url, version, postdata){
    return new Promise((resolve, reject) => {
      if(!url) reject("URL is missing");
      if(!version) reject("version is missing");

      this.headers.set('accept-version', version);
      var token = localStorage.getItem('basic_token');

      this.http.put(url, postdata, {'headers': this.headers.set('Authorization', `${token}`)}) 
        .subscribe((res: any) => {
          resolve(res);
        }, err => {
          if (err.status == 409) {
            return this.logOut()
          }
          reject(err);
        })
    })
  }

  // API delete method
  async delete(url, version){
    return new Promise((resolve, reject) => {
      if(!url) reject("URL is missing");
      if(!version) reject("version is missing");

      this.headers.set('accept-version', version);
      var token = localStorage.getItem('basic_token');

      this.http.delete(url, {'headers': this.headers.set('Authorization', `${token}`)}) 
        .subscribe((res: any) => {
          resolve(res);
        }, err => {
          if (err.status == 409) {
            return this.logOut()
          }
          reject(err);
        })
    })
  }

  /* Logout session starts */
  logOut() {
    var user_id = localStorage.getItem('user_id');
    if (user_id === undefined) {
      // Clear all storages
      this.clearStorage();
      this.popupNotificationsService.stopLoader();
      this.popupNotificationsService.presentToast('Session expired! Try logging in again');
      return this.router.navigate(['/intro']);
    }
    this.logoutFromServer(user_id)
    .then((res: any) => {
      // Clear all storages
      this.clearStorage();
      this.popupNotificationsService.presentToast('Session expired! Try logging in again');
      this.router.navigate(['/intro']);
    })
    .catch(err => {
      this.popupNotificationsService.presentToast(err.error.message || 'Connection error!')
    })
  }

  // Logout from server
  logoutFromServer(user_id: string) {
    this.popupNotificationsService.startLoader();
    return new Promise((resolve, reject) => {
      this.get(urls.logout + '/' + user_id, '1.0.0')
        .then((res: any) => {
        resolve(res);
        this.popupNotificationsService.stopLoader();
        }, err => {
        if (err.status == 404) {
          return this.clearStorage()
        }
        reject(err);
        this.popupNotificationsService.stopLoader();
      })
    })
  }

  // Clear all storage
  clearStorage() {
    localStorage.clear();
  }

  /* Logout session ends */

}
