// Export current api version
export const version = 'v2/';

// Onesignal API Keys
export const oneSignalAPIKey1 = 'ad115f24-4639-40f7-8def-d2811194b451';
export const oneSignalAPIKey2 = '874169365317';

// Current App Version
export const appVersion = '0.0.2';

// Get Current Date
export function todayDate(){
	let today = new Date();
	let dd = today.getDate();
	let mm = today.getMonth()+1;
	let mmStr = mm.toString();
	let ddStr = dd.toString();
	let yyyy = today.getFullYear();
	if (mm >= 10 && dd < 10) {
		ddStr = '0' + dd;
		return mm + '-' + ddStr + '-' + yyyy;
	}
	if (mm >= 10 && dd >= 10) {
		return mm + '-' + dd + '-' + yyyy;
	}
	if (mm < 10 && dd >= 10) {
		mmStr = '0' + mm;
		return mmStr + '-' + dd + '-' + yyyy;
	}
	if (mm<10 && dd<10) {
		ddStr = '0' + dd;
		mmStr = '0' + mm;
		return mmStr + '-' + ddStr + '-' + yyyy
	}
	return mm + '-' + dd + '-' + yyyy;
}

// Get current month
export function todayMonth() {
	let today = new Date();
	let mm = today.getMonth()+1;
	let mmStr = mm.toString();
	if (mm < 10) {
		mmStr = '0' + mm;
		return mmStr
	}
	return mm.toString();
}

// Get current year
export function todayYear() {
	let today = new Date();
	let yyyy = today.getFullYear();
	let yyyyStr = yyyy.toString();
	let yy = yyyyStr.substr(2)
	return yy;
}

// URI API Environments
export const apiUrls = {
	localhost: "http://localhost:5101/",
	development: "http://13.53.218.175:5101/",
}

// API Base URL
export const baseUrl = apiUrls.development;

// API Routes
export const urls = {
	countries: baseUrl + version + "platform/trovilo/countries",
	login: baseUrl + version + "login",
	logout: baseUrl + version + "logout",
	register: baseUrl + version + "register",
	forgot_password: baseUrl + version + "forgot-password",
	change_password: baseUrl + version + "change-password",
	users: baseUrl + version + "users",
	profiles: baseUrl + version + "platform/trovilo/profiles",
	transactions: baseUrl + version + "platform/trovilo/transactions",
	loan_limits: baseUrl + version + "platform/trovilo/loan-limits",
	support: baseUrl + version + "support",
	send_sms: baseUrl + version + "send-sms",
	verification: baseUrl + version + "verification",
	media: baseUrl + version + "media",
	paystack: baseUrl + version + "payment/paystack",
	flutterwave: baseUrl + version + "payment/flutterwave",
	bank_accounts: baseUrl + version + "platform/trovilo/bank-accounts",
	cards: baseUrl + version + "platform/trovilo/cards",
	loans: baseUrl + version + "platform/trovilo/loans",
	loan_rates: baseUrl + version + "platform/trovilo/loan-rates",
	settings: baseUrl + version + "platform/flame/settings",
	contacts: baseUrl + version + "platform/trovilo/contacts",
	locations: baseUrl + version + "platform/trovilo/locations",
	sims: baseUrl + version + "platform/trovilo/sims",
	dpo: baseUrl + version + "payment/dpo",
	wallets: baseUrl + version + "platform/trovilo/wallets",
}
