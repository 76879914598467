import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  interval_id: any;
  avatar: any;
  title: any;
  description: any;

  constructor(private popoverController: PopoverController, navParams: NavParams) {
      this.interval_id = navParams.get('interval_id');
      this.avatar = navParams.get('avatar');
      this.title = navParams.get('title');
      this.description = navParams.get('description');
   }

  ngOnInit() {

  }

  // Navigations
  dismiss() {
    this.popoverController.dismiss({
      'interval_id': this.interval_id,
    });
  }

}
