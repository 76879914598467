import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  
  storage: Storage | null = null;

	constructor(private storageModule: Storage) { 
    this.initializeStorage();
  }

  // Initialize storage
  async initializeStorage() {
    this.storage = await this.storageModule.create();
  }

  // Set
  async set(key, value) {
    return await this.storage.set(key, value);
  }

  // Get
  async get(key) {
    return await this.storage.get(key);
  }

  // Clear
  async clear() {
    return await this.storage.clear();

  }

  // Remove
  async remove(key) {
    return await this.storage.remove(key)

  }

}