import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { PopupNotificationsService } from '../../providers/popup-notifications.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.page.html',
  styleUrls: ['./verify-otp.page.scss'],
})
export class VerifyOtpPage implements OnInit {

  country_code: any;
  phone: any;
  email: any;
  otp_code: any;
  input_otp: any;

  constructor(private popupNotificationsService: PopupNotificationsService, navParams: NavParams, private modalController: ModalController) {
      // Get params from route
      this.country_code = navParams.get('country_code');
      this.phone = navParams.get('phone');
      this.email = navParams.get('email');
      this.otp_code = navParams.get('otp_code');
      this.input_otp = this.otp_code;
   }

  ngOnInit() {
  }

  ionViewWillEnter() {
    SplashScreen.hide();
    console.log(this.country_code, this.phone, this.otp_code)
  }

  /* Routes and navigation starts */

  // Dismiss modal
  dismiss() {
    this.modalController.dismiss()
  }

  // Verify otp
  verifyOtp() {
    if (this.input_otp !== this.otp_code) {
      return this.popupNotificationsService.presentToast('OTP Code does not match! Try again');
    }
    return this.proceed();
  }

  // Proceed
  proceed() {
    this.modalController.dismiss({
      'status': true,
    })
  }

  /* Routes and navigation ends */

}
