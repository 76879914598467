import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../providers/authGuard.guard';

const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: () => import('./intro/intro.module').then( m => m.IntroPageModule)
  // },
  {
    path: 'tabs',
    canActivate: [AuthGuard],
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'my-profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfilePageModule)
  },
  {
    path: 'loan-history',
    canActivate: [AuthGuard],
    loadChildren: () => import('./loan-history/loan-history.module').then( m => m.LoanHistoryPageModule)
  },
  {
    path: 'manage-bank-cards',
    canActivate: [AuthGuard],
    loadChildren: () => import('./manage-bank-cards/manage-bank-cards.module').then( m => m.ManageBankCardsPageModule)
  },
  {
    path: 'view-profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./view-profile/view-profile.module').then( m => m.ViewProfilePageModule)
  },
  {
    path: 'add-bank-account',
    canActivate: [AuthGuard],
    loadChildren: () => import('./add-bank-account/add-bank-account.module').then( m => m.AddBankAccountPageModule)
  },
  {
    path: 'loan-details',
    canActivate: [AuthGuard],
    loadChildren: () => import('./loan-details/loan-details.module').then( m => m.LoanDetailsPageModule)
  },
  {
    path: 'create-account',
    loadChildren: () => import('./create-account/create-account.module').then( m => m.CreateAccountPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'bvn-validation',
    canActivate: [AuthGuard],
    loadChildren: () => import('./bvn-validation/bvn-validation.module').then( m => m.BvnValidationPageModule)
  },
  {
    path: 'verify-otp',
    canActivate: [AuthGuard],
    loadChildren: () => import('./verify-otp/verify-otp.module').then( m => m.VerifyOtpPageModule)
  },
  {
    path: 'transaction-history',
    canActivate: [AuthGuard],
    loadChildren: () => import('./transaction-history/transaction-history.module').then( m => m.TransactionHistoryPageModule)
  },
  {
    path: 'successful',
    canActivate: [AuthGuard],
    loadChildren: () => import('./successful/successful.module').then( m => m.SuccessfulPageModule)
  },
  {
    path: 'failed',
    canActivate: [AuthGuard],
    loadChildren: () => import('./failed/failed.module').then( m => m.FailedPageModule)
  },
  {
    path: 'withdraw-loan',
    canActivate: [AuthGuard],
    loadChildren: () => import('./withdraw-loan/withdraw-loan.module').then( m => m.WithdrawLoanPageModule)
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard],
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'all-services',
    canActivate: [AuthGuard],
    loadChildren: () => import('./all-services/all-services.module').then( m => m.AllServicesPageModule)
  },
  {
    path: 'intro',
    loadChildren: () => import('./intro/intro.module').then( m => m.IntroPageModule)
  },
  {
    path: 'add-card',
    canActivate: [AuthGuard],
    loadChildren: () => import('./add-card/add-card.module').then( m => m.AddCardPageModule)
  },
  {
    path: 'payments',
    canActivate: [AuthGuard],
    loadChildren: () => import('./payments/payments.module').then( m => m.PaymentsPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'document-upload',
    loadChildren: () => import('./document-upload/document-upload.module').then( m => m.DocumentUploadPageModule)
  },
  {
    path: 'interest-calculator',
    loadChildren: () => import('./interest-calculator/interest-calculator.module').then( m => m.InterestCalculatorPageModule)
  },
  {
    path: 'payslip-upload',
    loadChildren: () => import('./payslip-upload/payslip-upload.module').then( m => m.PayslipUploadPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
