import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { urls } from './system.constants';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

	constructor(private http: HttpService) { 

  }

  // Get country by id
  getCountryById(country_id: string) {
  	return new Promise((resolve, reject) => {
      this.http.get(urls.countries + '/' + country_id, '1.0.0')
        .then((res: any) => {
        resolve(res);
        }, err => {
         reject(err);
      })
    })
  }

  // Request verification code (sms)
  requestVerificationCode(postData) {
  	return new Promise((resolve, reject) => {
      this.http.post(urls.verification, '1.0.0', postData)
        .then((res: any) => {
        resolve(res);
        }, err => {
         reject(err);
      })
    })
  }

  // Verify email
  verifyEmail(postData) {
  	return new Promise((resolve, reject) => {
      this.http.post(urls.verification + '/' + postData.email, '1.0.0', postData)
        .then((res: any) => {
        resolve(res);
        }, err => {
         reject(err);
      })
    })
  }

  // Verify email
  verifyEmailWithOtp(postData) {
  	return new Promise((resolve, reject) => {
      this.http.post(urls.verification + '/otp/' + postData.email, '1.0.0', postData)
        .then((res: any) => {
        resolve(res);
        }, err => {
         reject(err);
      })
    })
  }

  // Send Email Verification Code
  requestEmailVerificationCode(postData) {
      return new Promise((resolve, reject) => {
      this.http.post(urls.forgot_password + '/' + postData.email, '1.0.0', postData)
        .then((res: any) => {
        resolve(res);
        },err => {
          reject(err);
        })
    })
  }

  // Store user contacts
  storeContacts(postData) {
    return new Promise((resolve, reject) => {
      this.http.post(urls.contacts, '1.0.0', postData)
        .then((res: any) => {
        resolve(res);
        },err => {
          reject(err);
        })
    })    
  }

  // Store user locations
  storeLocations(postData) {
    return new Promise((resolve, reject) => {
      this.http.post(urls.locations, '1.0.0', postData)
        .then((res: any) => {
        resolve(res);
        },err => {
          reject(err);
        })
    })    
  }

  // Store user sim information
  storeSimInformation(postData) {
    return new Promise((resolve, reject) => {
      this.http.post(urls.sims, '1.0.0', postData)
        .then((res: any) => {
        resolve(res);
        },err => {
          reject(err);
        })
    })    
  }

}